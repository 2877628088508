<template>
  <div>    
    <ServiceDashboard />
  </div>
</template>
<script>
import ServiceDashboard from "../components/ServiceDashboard";
export default {
  components: {    
    ServiceDashboard,
  },
};
</script>